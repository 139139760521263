<template>
  <b-dropdown
    class="lang-switcher"
    size="sm"
    right
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
  >
    <template #button-content>
      <div class="dropdown-select">
        <span>{{ activeLang }}</span>
        <play-icon size="0.5x" class="dropdown-select-icon" />
      </div>
    </template>
    <b-dropdown-item :active="$i18n.locale === 'oz'" @click="setLocale('oz')">
      O'zbekcha
    </b-dropdown-item>
    <b-dropdown-item :active="$i18n.locale === 'uz'" @click="setLocale('uz')">
      Ўзбекча
    </b-dropdown-item>
    <!-- <b-dropdown-item :active="$i18n.locale === 'ru'" @click="setLocale('ru')">
      Русский
    </b-dropdown-item> -->
  </b-dropdown>
</template>

<script>
import { PlayIcon } from 'vue-feather-icons'

export default {
  components: {
    PlayIcon
  },
  methods: {
    setLocale(lang) {
      this.$i18n.locale = lang
      this.$router.push({
        params: { lang }
      })
    }
  },
  computed: {
    activeLang() {
      switch (this.$i18n.locale) {
        case 'ru':
          return 'Русский'
        case 'oz':
          return "O'zbekcha"
        default:
          return 'Узбекча'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lang-switcher {
  font-size: 0.875rem;
}
</style>
