import axios from 'axios'
import config from '@/config'

import Vue from 'vue'
import Vuex from 'vuex'

import newsModule from './news'
import pagesModule from './pages'
import currencyModule from './currency'
import weatherModule from './weather'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lastNewspaper: null
  },
  mutations: {
    setLastnewspaper(state, newspaper) {
      state.lastNewspaper = newspaper
    }
  },
  actions: {
    sendContacts(_, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.baseUrl}/contacts/`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    sendFeedback(_, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.baseUrl}/feedbacks/`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    getLastNewspaper({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/newspapers/`, {
            params: {
              _sort: 'published_at:desc',
              _limit: 1
            }
          })
          .then(response => {
            commit('setLastnewspaper', response.data[0])
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    getNewspapers(_, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/newspapers/`, {
            params
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    }
  },
  modules: {
    newsModule,
    pagesModule,
    currencyModule,
    weatherModule
  }
})
