<template>
  <nav class="nav-main">
    <div class="container">
      <ul class="main-list">
        <li>
          <router-link :to="`/${$i18n.locale}`" exact active-class="active">
            {{ $t('nav.main_page') }}
          </router-link>
        </li>
        <li v-for="cat in categories" :key="cat.id">
          <router-link
            :to="`/${$i18n.locale}/category/${cat.slug}`"
            active-class="active"
          >
            {{ cat[`name_${$i18n.locale}`] }}
          </router-link>
        </li>
      </ul>
      <ul class="secondary-list">
        <li>
          <router-link active-class="active" :to="`/${$i18n.locale}/videos`">
            <play-circle-icon class="icon" />
            <span>{{ $t('video') }}</span>
          </router-link>
        </li>
        <li>
          <router-link :to="`/${$i18n.locale}/news`">
            <camera-icon class="icon" />
            <span>{{ $t('photo') }}</span>
          </router-link>
        </li>
        <li v-show="false">
          <a href="#">
            <radio-icon class="icon" />
            <span>{{ $t('audio') }}</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { PlayCircleIcon, CameraIcon, RadioIcon } from 'vue-feather-icons'

export default {
  components: {
    PlayCircleIcon,
    CameraIcon,
    RadioIcon
  },
  computed: {
    categories() {
      return this.$store.state.newsModule.categories
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.nav-main {
  border-bottom: 1px solid $border-color;
  .container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  .main-list {
    display: flex;
    align-items: stretch;
    list-style: none;
    margin-bottom: 0;
    li {
      margin-right: 40px;
      display: flex;
      a {
        font-weight: 500;
        font-size: 1rem;
        padding: 25px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.2;
        color: $text;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0;
          transition: 0.2s all;
          background: #e7c06f;
        }
        &:hover,
        &.active {
          text-decoration: none;
          &::after {
            height: 3px;
          }
        }
      }
    }
  }

  .secondary-list {
    display: flex;
    align-items: stretch;
    list-style: none;
    margin-bottom: 0;
    li {
      margin-right: 30px;
      display: flex;
      &:last-child {
        margin-right: 0;
      }
      a {
        font-weight: normal;
        font-size: 0.875rem;
        padding: 25px 0;
        display: flex;
        align-items: center;
        color: $text;
        justify-content: center;
        line-height: 1.2;
        transition: 0.2s all;
        .icon {
          margin-right: 10px;
        }
        &:hover,
        &.active,
        &:focus {
          text-decoration: none;
          outline: none;
          color: $accent;
        }
      }
    }
  }
}
// ≥1200px
@media (max-width: 1199.98px) {
  .nav-main {
    .main-list li {
      margin-right: 20px;
    }
    .secondary-list li {
      margin-right: 20px;
    }
  }
}
</style>
