<template>
  <div class="poster" v-if="lastNewspaper">
    <div class="poster__header">
      <h3>Postda / На посту</h3>
      <div class="poster__number">
        {{ lastNewspaper.number }}-{{ $t('num') }}
      </div>
    </div>
    <div class="poster__body">
      <img :src="baseUrl + lastNewspaper.image.url" alt="Postda" />
    </div>
    <div class="poster__footer">
      <router-link :to="`/${$i18n.locale}/archivenewspaper`" class="text-link">
        {{ $t('all_numbers') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    lastNewspaper() {
      return this.$store.state.lastNewspaper
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.poster {
  background: #f0f0f3;
  border-radius: 2px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  &__number {
    color: $accent;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
    padding: 6px 10px;
    background: #f5e6c5;
    border-radius: 2px;
  }
  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 24px;
    .text-link {
      font-size: 1rem;
    }
  }
}
</style>
