<template>
  <div class="widget-outer">
    <transition name="fade">
      <div v-if="isWidgetOpen" class="widget">
        <div class="widget__overlay" @click="isWidgetOpen = false"></div>
        <div class="widget__window">
          <div class="container">
            <div class="special-box">
              <button
                class="special-box__button with-color"
                @click="settings.mode = 'with-color'"
              >
                <span>{{ $t('colored') }}</span>
              </button>
              <button
                class="special-box__button without-color"
                @click="settings.mode = 'without-color'"
              >
                <span>{{ $t('no_color') }}</span>
              </button>
              <button
                class="special-box__button invert-color"
                @click="settings.mode = 'invert-color'"
              >
                <span>{{ $t('invert') }}</span>
              </button>
              <div class="special-box__col">
                <div class="special-box__checkboxes">
                  <b-form-checkbox
                    v-model="settings.noImage"
                    name="check-button"
                    switch
                    class="d-flex align-items-center"
                  >
                    {{ $t('without_image') }}
                  </b-form-checkbox>
                </div>
                <div class="subheading">{{ $t('font_size') }}</div>
                <div class="special-box__range">
                  <div class="small">Aa</div>
                  <b-form-input
                    v-model="settings.fontSize"
                    type="range"
                    class="range"
                    min="12"
                    value="16"
                    max="24"
                  />
                  <div class="big">Aa</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    isOpenOnInit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    settings: {
      mode: 'with-color',
      fontSize: 16,
      noImage: false
    },
    isWidgetOpen: false
  }),
  watch: {
    isOpenOnInit(val) {
      this.isWidgetOpen = val
    },
    isWidgetOpen(val) {
      this.$emit('on-widget-change', val)
    },
    settings: {
      deep: true,
      handler() {
        localStorage.setItem('specialSettings', JSON.stringify(this.settings))
        if (this.settings.mode === 'without-color') {
          document.body.classList.add('blackAndWhite')
          document.body.classList.remove('blackAndWhiteInvert')
        } else if (this.settings.mode === 'invert-color') {
          document.body.classList.add('blackAndWhiteInvert')
          document.body.classList.remove('blackAndWhite')
        } else {
          document.body.classList.remove('blackAndWhite')
          document.body.classList.remove('blackAndWhiteInvert')
        }
        document
          .querySelectorAll('img')
          .forEach(
            imageItem =>
              (imageItem.style.display = this.settings.noImage ? 'none' : '')
          )
        document.querySelector(
          'html'
        ).style.fontSize = `${this.settings.fontSize}px`
      }
    }
  },
  created() {
    this.isWidgetOpen = this.isOpenOnInit
  },
  mounted() {
    this.settings = JSON.parse(localStorage.getItem('specialSettings')) || {
      mode: 'with-color',
      fontSize: 16,
      noImage: false
    }

    if (this.settings.mode === 'without-color') {
      document.body.classList.add('blackAndWhite')
    } else if (this.settings.mode === 'invert-color') {
      document.body.classList.add('blackAndWhiteInvert')
    }

    document
      .querySelectorAll('img')
      .forEach(
        imageItem =>
          (imageItem.style.display = this.settings.noImage ? 'none' : '')
      )

    document.querySelector(
      'html'
    ).style.fontSize = `${this.settings.fontSize}px`
  }
}
</script>

<style lang="scss" scoped>
.widget {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  bottom: 0;

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 6;
  }

  &__window {
    position: fixed;
    top: 42px;
    left: 0;
    right: 0;
    z-index: 7;
  }
}

.special-box {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  align-items: stretch;
  position: relative;
  justify-content: space-between;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1), 0 8px 30px rgba(0, 0, 0, 0.07);

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4px;
    padding: 40px;
    transition: 0.2s all;
    background-color: #0059b2;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 30px;
    width: 25%;
    cursor: pointer;

    span {
      color: #fff;
      border-radius: 4px;
      padding: 2px 8px;
      line-height: 1.2;
      font-size: 2rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    &:focus,
    &.active {
      outline: none;
      box-shadow: 0 0 0 8px #dbdbdb;
    }

    &.without-color {
      background-color: #5b5b5b;
      span {
        color: #fff;
      }
    }

    &.invert-color {
      background-color: #080808;
      span {
        color: #fff;
        background-color: #000;
      }
    }
  }

  &__close {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    font-size: 0.688rem;
    color: #fff;
    opacity: 0.7;
    font-weight: bold;

    &:hover,
    &:focus {
      outline: none;
      opacity: 1;
      cursor: pointer;
    }

    &::after {
      content: '';
      width: 33px;
      height: 33px;
      display: block;
      background: url('/images/icons/close-rounded-light.svg') no-repeat center
        center;
    }
  }

  &__col {
    display: flex;
    width: 25%;
    flex-direction: column;
    justify-content: space-between;
  }

  &__checkboxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  .heading {
    line-height: 1.2;
    margin-bottom: 10px;
  }

  &__range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(120, 120, 128, 0.2);
    border-bottom: 1px solid rgba(120, 120, 128, 0.2);

    .range {
      margin: 0 10px;
      width: 100%;
    }
  }

  .small {
    color: #8c8c8c;
    line-height: 1.2;
    font-size: 12px;
    font-weight: bold;
  }

  .big {
    color: #8c8c8c;
    font-weight: bold;
    line-height: 24px;
    font-size: 1.5rem;
  }
}
</style>
