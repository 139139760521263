<template>
  <footer class="footer-main">
    <b-container>
      <b-row class="align-items-center">
        <b-col cols="12" lg="6">
          <div class="logos-row d-flex align-items-center">
            <a href="https://iiv.uz/" target="_blank" class="mr-4">
              <img
                src="../assets/images/vazirlik-logo.png"
                alt="O'zbekiston Respublikasi ichgi ishlar vazirligi"
              />
            </a>
            <logo size="175" />
          </div>
          <div class="footer-main__copyright">
            {{ $t('copyright') }}
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="mt-4 mt-lg-0">
          <nav class="footer-main__nav">
            <ul>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/about`"
                  active-class="active"
                >
                  {{ $t('nav.about') }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/advertising`"
                  active-class="active"
                >
                  {{ $t('nav.adv') }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/feedback`"
                  active-class="active"
                >
                  {{ $t('nav.feedback') }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/contact`"
                  active-class="active"
                >
                  {{ $t('nav.contact') }}
                </router-link>
              </li>
            </ul>
          </nav>
          <div class="footer-main__info">
            <div class="info">
              <div class="info__heading">{{ $t('phones') }}:</div>
              <a class="info__content" href="tel:+998712314881"
                >+998 71 231-48-81</a
              >
            </div>
            <div class="info">
              <div class="info__heading">{{ $t('fax') }}:</div>
              <a class="info__content" href="tel:+998712314881"
                >+998 71 231-41-81</a
              >
            </div>
            <div class="info">
              <div class="info__heading">{{ $t('email') }}</div>
              <a class="info__content" href="mailto:info@postda.uz"
                >info@postda.uz</a
              >
            </div>
          </div>
          <social-links />
        </b-col>
      </b-row>
    </b-container>
    <div class="footer-main__sub">
      <span
        >Copyright © 2021 <a href="https://t.me/JahongirFm" target="_blank">JSD WEB SECURITY</a> All
        rights reserved.</span
      >
    </div>
  </footer>
</template>

<script>
import Logo from '@/components/Logo'
import SocialLinks from '@/components/SocialLinks'

export default {
  components: {
    Logo,
    SocialLinks
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.footer-main {
  padding-top: 24px;
  background: #f0f0f3;
  &__sub {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    margin-top: 24px;
    line-height: 1.2;
    border-top: 1px solid $border-color;
    font-size: 0.875rem;
  }
  &__copyright {
    color: $text-light;
    line-height: 1.4;
    font-size: 0.875rem;
    margin-top: 30px;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 34px;
  }
  &__nav {
    display: flex;
    width: 100%;
    ul {
      margin-bottom: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      list-style: none;
      a {
        color: $text;
        font-weight: 500;
        line-height: 1.2;
        transition: color 0.2s;
        &:hover,
        &.active,
        &:focus {
          text-decoration: none;
          color: $accent;
        }
      }
    }
  }
}

// >480
@media (max-width: 480px) {
  .footer-main {
    &__nav {
      ul {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        li {
          margin-right: 0;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &__info {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
      .info {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
