import axios from 'axios'
import config from '@/config'

export default {
  state: () => ({
    news: [],
    newsCount: 0,
    singleNews: null,
    videos: [],
    videosCount: 0,
    singleVideo: null,
    category: null,
    categories: []
  }),
  actions: {
    fetchNews({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/articles`, {
            params: {
              ...params,
              status: 'published'
            }
          })
          .then(response => {
            commit('setNews', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSingleNews({ commit }, newsId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/articles/${newsId}`, {
            params: {
              timestamp: new Date().getTime()
            }
          })
          .then(response => {
            commit('setSingleNews', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchNewsCount({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/articles/count`, {
            params: { ...params, status: 'published' }
          })
          .then(response => {
            commit('setNewsCount', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    fetchVideos({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/videos`, {
            params: { ...params, status: 'published' }
          })
          .then(response => {
            commit('setVideos', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSingleVideo({ commit }, newsId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/videos/${newsId}`)
          .then(response => {
            commit('setSingleVideo', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchVideosCount({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/videos/count`, {
            params: { ...params, status: 'published' }
          })
          .then(response => {
            commit('setVideosCount', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    fetchCategories({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/categories`, {
            params: {
              '': new Date().getTime()
            }
          })
          .then(response => {
            commit('setCategories', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCategory({ commit }, slug) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/categories`, { params: { slug } })
          .then(response => {
            commit('setCategory', response.data[0])
            resolve()
          })
          .catch(error => reject(error))
      })
    }
  },
  mutations: {
    setNews(state, news) {
      state.news = news
    },
    setSingleNews(state, news) {
      state.singleNews = news
    },
    setNewsCount(state, count) {
      state.newsCount = count
    },
    setVideos(state, videos) {
      state.videos = videos
    },
    setSingleVideo(state, video) {
      state.singleVideo = video
    },
    setVideosCount(state, count) {
      state.videosCount = count
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setCategory(state, category) {
      state.category = category
    }
  }
}
