<template>
  <div class="page">
    <header-main v-if="!isMobile" />
    <header-mobile
      v-if="isMobile"
      @menu-click="isSidebarOpen = !isSidebarOpen"
    />
    <sidebar-nav
      v-if="isMobile"
      @close="isSidebarOpen = false"
      :is-open="isSidebarOpen"
    />
    <div class="page__socials" v-if="!isMobile">
      <social-links vertical />
    </div>
    <div class="page__body">
      <b-container class="my-3">
        <b-row>
          <b-col cols="12" lg="8" class="my-3">
            <router-view />
          </b-col>
          <b-col cols="12" lg="4" class="my-3">
            <h3 class="mb-4">{{ $t('most_read') }}</h3>
            <loader :visible="isLoading" />
            <b-row v-if="!isLoading">
              <b-col
                v-for="news in popularNews"
                :key="news.id"
                cols="12"
                class="mb-4"
              >
                <card-item :item="news" no-image has-underline />
              </b-col>
            </b-row>
            <poster />
            <div class="social-networks-sidebar mt-4">
              <h3 class="mb-3">{{ $t('socials_title') }}</h3>
              <social-networks />
            </div>
          </b-col>
        </b-row>
      </b-container>
      <mobile-app-section class="mt-4" />
    </div>
    <footer-main />
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import HeaderMain from '@/components/HeaderMain'
import FooterMain from '@/components/FooterMain'
import CardItem from '@/components/cards/CardItem'
import Poster from '@/components/Poster'
import SocialNetworks from '@/components/SocialNetworks'
import SocialLinks from '@/components/SocialLinks'
import MobileAppSection from '@/components/MobileAppSection'
import SidebarNav from '@/components/SidebarNav'
import HeaderMobile from '@/components/HeaderMobile'
import isMobileMixin from '@/mixins/isMobile.mixin'

export default {
  mixins: [isMobileMixin],
  components: {
    HeaderMain,
    FooterMain,
    CardItem,
    Poster,
    SocialNetworks,
    SocialLinks,
    MobileAppSection,
    HeaderMobile,
    SidebarNav,
    Loader
  },
  data: () => ({
    isSidebarOpen: false,
    isLoading: false,
    popularNews: []
  }),
  watch: {
    $route: {
      deep: true,
      handler() {
        this.isSidebarOpen = false
      }
    }
  },
  async created() {
    this.isLoading = true
    const news = await this.$store.dispatch('fetchNews', {
      _sort: 'views:desc',
      _limit: 6
    })
    this.popularNews = news
    this.isLoading = false
  }
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__body {
    flex: 1;
    background: #fff;
  }
}

// ≥992px
@media (max-width: 991.98px) {
  .page {
    &__body {
      padding-top: 90px;
    }
  }
}
</style>
