import axios from 'axios'
import config from '@/config'

export default {
  state: () => ({
    currentWeather: '',
    currentWeatherIcon: ''
  }),

  actions: {
    async fetchWeather({ commit }, cityId) {
      try {
        const response = await axios.get(
          'https://api.openweathermap.org/data/2.5/weather',
          {
            params: {
              id: cityId,
              units: 'metric',
              appid: config.weatherApiKey
            }
          }
        )
        commit('setWeatherData', response.data)
      } catch (err) {
        return err
      }
    }
  },

  mutations: {
    setWeatherData(state, data) {
      state.currentWeather = data.main.temp
      state.currentWeatherIcon = `https://openweathermap.org/img/w/${data.weather[0].icon}.png`
    }
  }
}
