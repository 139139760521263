<template>
  <router-link
    data-aos="fade-up"
    :to="
      `/${$i18n.locale}/${cardType === 'video' ? 'videos' : 'news'}/${item.id}`
    "
    :class="
      `card-item ${
        hasUnderline ? 'card-item--underline' : ''
      } card-item--${size}`
    "
  >
    <div class="card-item__img-wrapper" v-if="!noImage">
      <img
        :src="baseUrl + item.thumbnail.url"
        :alt="item.thumbnail.alternativeText"
      />
      <play-circle-icon
        class="card-item__icon"
        size="3x"
        v-if="cardType === 'video'"
      />
      <camera-icon
        class="card-item__icon"
        size="3x"
        v-else-if="cardType === 'image'"
      />
      <radio-icon
        class="card-item__icon"
        size="3x"
        v-else-if="cardType === 'audio'"
      />
    </div>
    <div class="card-item__content">
      <div class="card-item__title">
        {{ slicedTitle }}
      </div>
      <div class="card-item__description" v-if="hasDescription">
        {{ item[`description_${$i18n.locale}`] }}
      </div>
      <div class="card-item__details">
        <div class="card-item__category" v-if="item.category">
          {{ item.category[`name_${$i18n.locale}`] }}
        </div>
        <div class="card-item__detail time">
          {{ $date(item.publish_at || item.published_at).format('HH:mm') }}
        </div>
        <div class="card-item__detail date">
          {{ $date(item.publish_at || item.published_at).format('DD.MM.YYYY') }}
        </div>
        <div class="card-item__detail views" v-if="item.views">
          <eye-icon class="icon" size="1.3x" />
          <span>{{ item.views }}</span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import {
  EyeIcon,
  PlayCircleIcon,
  CameraIcon,
  RadioIcon
} from 'vue-feather-icons'

export default {
  components: {
    EyeIcon,
    PlayCircleIcon,
    CameraIcon,
    RadioIcon
  },
  props: {
    item: {
      type: Object,
      // Temp
      default() {
        return {
          id: 2,
          title_uz: 'undefined uz',
          title_ru: 'undefined ru',
          title_oz: 'undefined oz',
          content_uz: '**content uz**',
          content_ru: '**conetnt ru**',
          content_oz: '**content oz**',
          publish_at: null,
          description_uz: null,
          description_ru: null,
          descripiton_oz: null,
          category: {
            id: 1,
            name_uz: 'Узбекистон',
            name_ru: 'Узбекистан',
            name_oz: "O'zbekiston",
            slug: 'uzbekiston',
            published_at: '2021-01-08T09:57:16.751Z',
            created_at: '2021-01-08T09:57:14.520Z',
            updated_at: '2021-01-08T09:57:16.820Z'
          },
          views: '7',
          published_at: '2021-01-08T10:45:29.932Z',
          created_at: '2021-01-08T10:45:27.838Z',
          updated_at: '2021-01-08T10:48:36.892Z',
          thumbnail: {
            id: 1,
            name: 'building-about.jpg',
            alternativeText: '',
            caption: '',
            width: 820,
            height: 599,
            formats: {
              small: {
                ext: '.jpg',
                url: '/uploads/small_building_about_5012e9b05f.jpg',
                hash: 'small_building_about_5012e9b05f',
                mime: 'image/jpeg',
                name: 'small_building-about.jpg',
                path: null,
                size: 44.22,
                width: 500,
                height: 365
              },
              medium: {
                ext: '.jpg',
                url: '/uploads/medium_building_about_5012e9b05f.jpg',
                hash: 'medium_building_about_5012e9b05f',
                mime: 'image/jpeg',
                name: 'medium_building-about.jpg',
                path: null,
                size: 88.12,
                width: 750,
                height: 548
              },
              thumbnail: {
                ext: '.jpg',
                url: '/uploads/thumbnail_building_about_5012e9b05f.jpg',
                hash: 'thumbnail_building_about_5012e9b05f',
                mime: 'image/jpeg',
                name: 'thumbnail_building-about.jpg',
                path: null,
                size: 10.64,
                width: 214,
                height: 156
              }
            },
            hash: 'building_about_5012e9b05f',
            ext: '.jpg',
            mime: 'image/jpeg',
            size: 104.34,
            url: '/uploads/building_about_5012e9b05f.jpg',
            previewUrl: null,
            provider: 'local',
            provider_metadata: null,
            created_at: '2021-01-08T09:56:15.168Z',
            updated_at: '2021-01-08T09:56:15.233Z'
          }
        }
      }
    },
    noImage: {
      type: Boolean,
      default: false
    },
    hasUnderline: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'sm'
    },
    cardType: {
      type: String,
      default: 'news'
    },
    hasDescription: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    slicedTitle() {
      const txt = this.item[`title_${this.$i18n.locale}`]
      return txt.length > 52 ? txt.slice(0, 52) + '...' : txt
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.card-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  &:hover,
  &:focus {
    outline: none;
    .card-item {
      &__title {
        opacity: 0.8;
      }
      &__img-wrapper img {
        transform: scale(1.05);
      }
    }
  }
  &--underline {
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color;
  }
  &--md {
    flex-direction: column;
    .card-item {
      &__img-wrapper {
        width: 100%;
        height: 260px;
        margin-right: 0;
        margin-bottom: 18px;
      }
    }
  }
  &--vertical-md {
    .card-item {
      &__img-wrapper {
        height: 260px;
        min-width: 380px;
        width: 380px;
      }
      &__title {
        font-size: 1.25rem;
      }
    }
  }
  &__description {
    color: $text;
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 20px;
  }
  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__category {
    color: $accent;
    background: rgba(231, 192, 111, 0.4);
    padding: 4px 8px;
    border-radius: 2px;
    margin-right: 8px;
  }
  &__details {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    color: $text-light;
    line-height: 1.2;
  }
  &__detail {
    display: flex;
    align-items: center;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    .icon {
      margin-right: 5px;
    }
  }
  &__title {
    font-weight: normal;
    line-height: 1.4;
    font-size: 1rem;
    margin-bottom: 15px;
    color: $text-dark;
    transition: opacity 0.2s;
    overflow-wrap: anywhere;
  }
  &__img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 2px;
    width: 130px;
    min-width: 130px;
    height: 110px;
    overflow: hidden;
    margin-right: 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform-origin: center;
      transition: transform 0.2s;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.8;
      transition: opacity 0.2s;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.9) 100%
      );
    }
  }
}

// ≥768
@media (max-width: 767.98px) {
  .card-item {
    &--vertical-md {
      flex-direction: column;
      .card-item {
        &__img-wrapper {
          width: 100%;
          margin-bottom: 15px;
          min-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .card-item {
    &--sm {
      .card-item {
        &__category {
          display: none;
        }
        &__title {
          font-size: 0.875rem;
        }
      }
    }
  }
}
</style>
