<template>
  <div class="social-links" :class="{ 'social-links--vertical': vertical }">
    <a href="https://www.facebook.com/postdauz/" target="_blank">
      <facebook-icon size="1.45x" />
    </a>
    <a href="https://www.instagram.com/postda_uz/" target="_blank">
      <instagram-icon size="1.45x" />
    </a>
    <a href="https://t.me/postdarasmiy" target="_blank">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.067 2.47938C17.8947 2.33068 17.6851 2.23193 17.4608 2.19383C17.2364 2.15573 17.006 2.17972 16.7943 2.26321L2.37579 7.92766C2.12451 8.02642 1.91196 8.20387 1.76993 8.43349C1.62791 8.6631 1.56405 8.93253 1.5879 9.20146C1.61175 9.47039 1.72203 9.72437 1.90225 9.92541C2.08246 10.1264 2.32292 10.2637 2.58766 10.3167L6.25008 11.0494V15.6238C6.24986 15.8711 6.32304 16.113 6.46035 16.3187C6.59766 16.5244 6.79293 16.6847 7.02142 16.7793C7.24991 16.874 7.50135 16.8987 7.74389 16.8504C7.98644 16.802 8.20918 16.6828 8.38391 16.5077L10.4108 14.4809L13.487 17.188C13.7135 17.3891 14.0058 17.5004 14.3087 17.5008C14.4403 17.5006 14.5712 17.4799 14.6964 17.4393C14.903 17.3738 15.0888 17.2552 15.2353 17.0955C15.3818 16.9357 15.4838 16.7403 15.5312 16.5288L18.4698 3.70602C18.521 3.48428 18.5105 3.25276 18.4395 3.03655C18.3685 2.82033 18.2396 2.62768 18.067 2.47946V2.47938ZM6.73024 9.87055L2.83297 9.0911L13.7456 4.80391L6.73024 9.87055ZM7.50008 15.6232V11.9195L9.4704 13.6533L7.50008 15.6232ZM14.3127 16.2495L7.87586 10.585L17.145 3.89071L14.3127 16.2495Z"
          fill="#666666"
        />
      </svg>
    </a>
  </div>
</template>

<script>
import { FacebookIcon, InstagramIcon } from 'vue-feather-icons'
export default {
  components: {
    FacebookIcon,
    InstagramIcon
  },
  props: {
    vertical: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.social-links {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  a {
    color: $text-light;
    transition: 0.2s all;
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  &--vertical {
    flex-direction: column;
    a {
      margin-right: 0;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
