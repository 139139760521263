<template>
  <div class="weather">
    <b-dropdown
      class="weather-select"
      size="sm"
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <div class="dropdown-select">
          <span>{{ selectedRegionObj.label }}</span>
          <play-icon size="0.5x" class="dropdown-select-icon" />
        </div>
      </template>
      <b-dropdown-item
        v-for="region in regions"
        :key="region.id"
        :active="region.id === selectedRegion"
        @click="selectedRegion = region.id"
      >
        {{ region.label }}
      </b-dropdown-item>
    </b-dropdown>
    <div class="weather-value ml-2">
      <img
        :src="currentWeatherIcon"
        v-if="currentWeatherIcon"
        class="icon"
        width="40"
        height="40"
      />
      <span>
        {{
          (currentWeather >= 0 ? `+${currentWeather}` : currentWeather) || '-'
        }}°C
      </span>
    </div>
  </div>
</template>

<script>
import { PlayIcon } from 'vue-feather-icons'

export default {
  components: {
    PlayIcon
  },
  data: () => ({
    selectedRegion: 1512569
  }),
  computed: {
    currentWeather() {
      return this.$store.state.weatherModule.currentWeather
    },
    currentWeatherIcon() {
      return this.$store.state.weatherModule.currentWeatherIcon
    },
    regions() {
      return [
        { value: 'andijon', label: this.$t('regions.andijon'), id: 1514588 },
        { value: 'buxoro', label: this.$t('regions.buxoro'), id: 1217662 },
        { value: 'fargona', label: this.$t('regions.fargona'), id: 1514019 },
        { value: 'jizzakh', label: this.$t('regions.jizzakh'), id: 1513886 },
        { value: 'namangan', label: this.$t('regions.namangan'), id: 1513157 },
        { value: 'navoiy', label: this.$t('regions.navoiy'), id: 1513131 },
        { value: 'nukus', label: this.$t('regions.nukus'), id: 601294 },
        { value: 'qarshi', label: this.$t('regions.qarshi'), id: 1216311 },
        {
          value: 'samarqand',
          label: this.$t('regions.samarqand'),
          id: 1216265
        },
        { value: 'sirdaryo', label: this.$t('regions.sirdaryo'), id: 1512770 },
        { value: 'termiz', label: this.$t('regions.termiz'), id: 1215957 },
        { value: 'toshkent', label: this.$t('regions.toshkent'), id: 1512569 },
        { value: 'urgench', label: this.$t('regions.urgench'), id: 1512473 }
      ]
    },
    selectedRegionObj() {
      return this.regions.find(reg => reg.id === this.selectedRegion)
    }
  },
  watch: {
    selectedRegion: {
      immediate: true,
      handler(val) {
        this.$store.dispatch('fetchWeather', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.weather {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  &-value {
    display: flex;
    align-items: center;
    color: $text-light;
  }
}
</style>
