import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [
      {
        meta: {
          layout: 'default'
        },
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        meta: {
          layout: 'default'
        },
        path: 'about',
        name: 'About',
        component: () => import('@/views/About.vue')
      },
      {
        meta: {
          layout: 'article'
        },
        path: 'uzbekistan',
        name: 'Uzbekistan',
        component: () => import('@/views/Uzbekistan.vue')
      },
      {
        meta: {
          layout: 'main'
        },
        path: 'archivenewspaper',
        name: 'Archive Newspaper',
        component: () => import('@/views/ArchiveNewspaper.vue')
      },
      {
        meta: {
          layout: 'main'
        },
        path: 'advertising',
        name: 'Advertising',
        component: () => import('@/views/Advertising.vue')
      },
      {
        meta: {
          layout: 'main'
        },
        path: 'search/:query',
        name: 'Search',
        component: () => import('@/views/Search.vue')
      },
      {
        meta: {
          layout: 'main'
        },
        path: 'contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue')
      },
      {
        meta: {
          layout: 'main'
        },
        path: 'feedback',
        name: 'Feedback',
        component: () => import('@/views/Feedback.vue')
      },
      {
        meta: {
          layout: 'article'
        },
        path: 'news/:id',
        name: 'Single Article',
        component: () => import('@/views/SingleArticle.vue')
      },
      {
        meta: {
          layout: 'article'
        },
        path: 'news',
        name: 'All News',
        component: () => import('@/views/AllNews.vue')
      },
      {
        meta: {
          layout: 'article'
        },
        path: 'videos',
        name: 'All Videos',
        component: () => import('@/views/AllVideos.vue')
      },
      {
        meta: {
          layout: 'article'
        },
        path: 'videos/:id',
        name: 'Single Videl',
        component: () => import('@/views/SingleVideo.vue')
      },
      {
        meta: {
          layout: 'article'
        },
        path: 'category/:slug',
        name: 'Categories Article',
        component: () => import('@/views/CategoriesNews.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
