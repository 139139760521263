<template>
  <div class="photo-news-slider">
    <div class="photo-news-slider__header">
      <h3>{{ $t('photo_news') }}</h3>
      <div class="photo-news-slider__arrows">
        <button class="arrow photo-news-slider__prev">
          <img src="../assets/images/icons/prev-arrow-dark.svg" alt="<" />
        </button>
        <button class="arrow photo-news-slider__next">
          <img src="../assets/images/icons/next-arrow-dark.svg" alt="<" />
        </button>
      </div>
    </div>
    <div class="photo-news-slider__body">
      <div class="photo-news-slider__slider swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <card-item size="md" card-type="image" />
          </div>
          <div class="swiper-slide">
            <card-item size="md" card-type="image" />
          </div>
          <div class="swiper-slide">
            <card-item size="md" card-type="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import CardItem from '@/components/cards/CardItem'

export default {
  components: {
    CardItem
  },
  data: () => ({
    photoNewsSlider: null
  }),
  mounted() {
    this.photoNewsSlider = new Swiper('.photo-news-slider__slider', {
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: '.photo-news-slider__next',
        prevEl: '.photo-news-slider__prev'
      }
    })
  },
  beforeDestroy() {
    this.photoNewsSlider.destroy()
  }
}
</script>

<style lang="scss" scoped>
.photo-news-slider {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  &__arrows {
    display: flex;
    align-items: center;
    .arrow {
      width: 25px;
      height: 25px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      opacity: 0.8;
      transition: opacity 0.2s;
      background: transparent;
      border: none;
      &:last-child {
        margin-right: 0;
      }
      &:hover,
      &:focus {
        outline: none;
        opacity: 1;
      }
    }
  }
}
</style>
