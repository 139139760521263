import Vue from 'vue'
import config from '@/config'
import VueMeta from 'vue-meta'
import VueTheMask from 'vue-the-mask'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'swiper/css/swiper.css'
import 'aos/dist/aos.css'
import App from './App.vue'
import router from './router'
import store from './store'
import clickOutsideDirective from '@/directives/click-outside.directive'
import i18n from './i18n'
import dayjs from 'dayjs'

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs
    }
  }
})

Vue.use(VueMeta)
Vue.use(VueTheMask)
Vue.use(BootstrapVue)
Vue.directive('click-outside', clickOutsideDirective)

Vue.prototype.baseUrl = config.baseUrl

router.beforeEach((to, from, next) => {
  let language = to.params.lang
  if (!language) {
    language = 'uz'
  }

  i18n.locale = language
  // if (!i18n.availableLocales.includes(to.params.lang)) {
  //   next('/uz')
  // }
  next()
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
