<template>
  <div class="sidebar-nav" :class="{ 'sidebar-nav--opened': isOpen }">
    <div class="sidebar-nav__backdrop" @click="$emit('close')"></div>
    <aside class="sidebar-nav__sidebar">
      <div class="sidebar-nav__top">
        <div class="d-flex align-items-center justify-content-between">
          <marquee class="demo-text">
            <span>{{ $t('beta_mode') }}</span>
          </marquee>
          <button class="close-btn" @click="$emit('close')">
            <x-icon />
          </button>
        </div>
        <social-links class="w-100 mt-2" />
      </div>
      <div class="sidebar-nav__middle">
        <div class="header-date">
          {{ currentDate }}
        </div>
        <div class="courses mt-2">
          <div class="course course--up">USD {{ usd || '-' }}</div>
          <div class="course course--down">EUR {{ eur || '-' }}</div>
          <div class="course course--up">RUB {{ rub || '-' }}</div>
        </div>
      </div>
      <nav class="sidebar-nav__nav">
        <ul class="main-list">
          <li>
            <router-link :to="`/${$i18n.locale}`" exact active-class="active">
              {{ $t('nav.main_page') }}
            </router-link>
          </li>
          <li v-for="cat in categories" :key="cat.id">
            <router-link
              :to="`/${$i18n.locale}/category/${cat.slug}`"
              active-class="active"
            >
              {{ cat[`name_${$i18n.locale}`] }}
            </router-link>
          </li>
        </ul>
        <ul class="secondary-list">
          <li>
            <router-link active-class="active" :to="`/${$i18n.locale}/videos`">
              <play-circle-icon class="icon" />
              <span>{{ $t('video') }}</span>
            </router-link>
          </li>
          <router-link :to="`/${$i18n.locale}/news`">
            <camera-icon class="icon" />
            <span>{{ $t('photo') }}</span>
          </router-link>
          <li v-show="false">
            <a href="#">
              <radio-icon class="icon" />
              <span>{{ $t('audio') }}</span>
            </a>
          </li>
        </ul>
        <ul class="bottom-list">
          <li>
            <router-link :to="`/${$i18n.locale}/about`" active-class="active">
              {{ $t('nav.about') }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="`/${$i18n.locale}/advertising`"
              active-class="active"
            >
              {{ $t('nav.adv') }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="`/${$i18n.locale}/feedback`"
              active-class="active"
            >
              {{ $t('nav.feedback') }}
            </router-link>
          </li>
          <li>
            <router-link :to="`/${$i18n.locale}/contact`" active-class="active">
              {{ $t('nav.contact') }}
            </router-link>
          </li>
        </ul>
      </nav>
    </aside>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { XIcon } from 'vue-feather-icons'
import SocialLinks from '@/components/SocialLinks'
import { PlayCircleIcon, CameraIcon, RadioIcon } from 'vue-feather-icons'

export default {
  components: {
    XIcon,
    SocialLinks,
    PlayCircleIcon,
    CameraIcon,
    RadioIcon
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch('getCurrency', 'USD'),
      this.$store.dispatch('getCurrency', 'RUB'),
      this.$store.dispatch('getCurrency', 'EUR')
    ])
  },
  computed: {
    categories() {
      return this.$store.state.newsModule.categories
    },
    currentDate() {
      return dayjs(new Date())
        .locale(this.$i18n.locale === 'oz' ? 'uz-latn' : this.$i18n.locale)
        .format('DD MMMM, dddd')
    },
    ...mapGetters(['usd', 'rub', 'eur'])
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(val) {
        if (val) {
          document.body.classList.add('no-scroll')
        } else {
          document.body.classList.remove('no-scroll')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.sidebar-nav {
  &--opened {
    .sidebar-nav {
      &__backdrop {
        opacity: 1;
        visibility: visible;
      }
      &__sidebar {
        transform: translateX(0);
      }
    }
  }
  &__backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    transition: 0.4s all;
    opacity: 0;
    visibility: hidden;
  }
  &__sidebar {
    position: fixed;
    right: 0;
    max-width: 320px;
    width: 100%;
    top: 0;
    bottom: 0;
    transform: translateX(100%);
    transition: 0.4s all;
    z-index: 10;
    background: #ffffff;
    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    .header-date {
      color: $text-light;
    }
  }

  &__top {
    padding: 16px;
    border-bottom: 1px solid $border-color;
  }

  .close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-light;
    transition: 0.2s all;
    background: transparent;
    border: none;
    margin-left: 20px;
    &:hover,
    &:focus {
      outline: none;
      background: rgba(#000, 0.05);
      text-decoration: none;
    }
    &:active {
      background: rgba(#000, 0.1);
    }
  }

  &__middle {
    padding: 12px;
    font-size: 0.75rem;
    border-bottom: 1px solid $border-color;
  }

  &__nav {
    padding: 5px 16px;
    overflow: auto;
    height: 100%;
    ul {
      list-style: none;
    }

    .main-list {
      display: flex;
      align-items: stretch;
      list-style: none;
      flex-direction: column;
      li {
        display: flex;
        width: 100%;
        a {
          font-weight: 500;
          font-size: 1rem;
          width: 100%;
          padding: 14px 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          line-height: 1.2;
          color: $text;
          position: relative;
          border-bottom: 1px solid $border-color;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0;
            transition: 0.2s all;
            background: #e7c06f;
          }
          &:hover,
          &.active {
            text-decoration: none;
            &::after {
              height: 3px;
            }
          }
        }
      }
    }

    .secondary-list {
      display: flex;
      align-items: stretch;
      list-style: none;
      margin-bottom: 0;
      flex-direction: column;
      li {
        display: flex;
        a {
          font-weight: normal;
          font-size: 0.875rem;
          width: 100%;
          padding: 12px 0;
          display: flex;
          align-items: center;
          color: $text-light;
          justify-content: flex-start;
          line-height: 1.2;
          transition: 0.2s all;
          border-bottom: 1px solid $border-color;
          .icon {
            margin-right: 10px;
          }
          &:hover,
          &.active,
          &:focus {
            text-decoration: none;
            outline: none;
            color: $accent;
          }
        }
      }
    }

    .bottom-list {
      list-style: none;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: stretch;
        a {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-start;
          padding: 10px 0;
          color: $text-light;
          transition: 0.2s all;
          &:hover,
          &:focus,
          &.active {
            outline: none;
            background: rgba(#000, 0.05);
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
