<template>
  <router-link :to="`/${$i18n.locale}`" class="logo">
    <img
      src="../assets/images/logo.png"
      :style="{ 'max-width': size + 'px' }"
      alt="Postda.uz"
    />
  </router-link>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 204
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  display: inline-flex;
  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
