<template>
  <div class="main-slider" data-aos="fade-up">
    <div class="swiper-container slider-main">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in items" :key="item.id">
          <card-item-lg :item="item" />
        </div>
      </div>
    </div>
    <div class="main-slider__arrows">
      <button class="arrow main-slider__prev">
        <img src="../assets/images/icons/prev-arrow.svg" alt="<" />
      </button>
      <button class="arrow main-slider__next">
        <img src="../assets/images/icons/next-arrow.svg" alt=">" />
      </button>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import CardItemLg from '@/components/cards/CardItemLg'

export default {
  components: {
    CardItemLg
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data: () => ({
    mainSlider: null
  }),
  mounted() {
    this.mainSlider = new Swiper('.slider-main', {
      spaceBetween: 30,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      loop: true,
      navigation: {
        nextEl: '.main-slider__next',
        prevEl: '.main-slider__prev'
      }
    })
  },
  beforeDestroy() {
    this.mainSlider.destroy()
  }
}
</script>

<style lang="scss" scoped>
.main-slider {
  position: relative;
  &__arrows {
    position: absolute;
    bottom: 24px;
    right: 24px;
    z-index: 1;
    display: flex;
    align-items: center;
    .arrow {
      width: 25px;
      height: 25px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      opacity: 0.8;
      transition: opacity 0.2s;
      background: transparent;
      border: none;
      &:last-child {
        margin-right: 0;
      }
      &:hover,
      &:focus {
        outline: none;
        opacity: 1;
      }
    }
  }
}
</style>
