<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import AOS from 'aos'
import EmptyLayout from '@/layouts/EmptyLayout'
import DefaultLayout from '@/layouts/DefaultLayout'
import ArticleLayout from '@/layouts/ArticleLayout'
import MainLayout from '@/layouts/MainLayout'

export default {
  components: {
    EmptyLayout,
    DefaultLayout,
    ArticleLayout,
    MainLayout
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  },
  mounted() {
    this.$store.dispatch('fetchCategories')
    this.$store.dispatch('getLastNewspaper')
    AOS.init({
      once: true
    })
  },
  metaInfo() {
    return {
      title: 'postda.uz',
      titleTemplate: `%s | ${this.$t('postda')}`
    }
  }
}
</script>

<style lang="scss">
@import '/assets/scss/main.scss';
</style>
