import axios from 'axios'
import config from '@/config'

export default {
  state: () => ({
    aboutUs: null,
    leaders: []
  }),
  actions: {
    fetchAbout({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/about-us`)
          .then(response => {
            commit('setAbout', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchLeaders({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/leaders`)
          .then(response => {
            commit('setLeaders', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  mutations: {
    setAbout(state, about) {
      state.aboutUs = about
    },
    setLeaders(state, leaders) {
      state.leaders = leaders
    }
  }
}
