<template>
  <header class="header-main">
    <div class="header-main__top">
      <div class="header-search">
        <form
          @submit.prevent="searchSubmit"
          class="header-main__search"
          :class="{ opened: isSearchOpened }"
        >
          <div class="container">
            <input
              type="text"
              :placeholder="$t('search_site')"
              class="header-search-input"
              ref="searchInput"
              v-model.trim="searchValue"
            />
            <button class="search-btn" type="submit">
              <search-icon size="1.4x" />
            </button>
          </div>
        </form>
        <div
          class="search-backdrop"
          :class="{ 'search-backdrop--active': isSearchOpened }"
          @click="isSearchOpened = false"
        ></div>
      </div>
      <widget
        :is-open-on-init="isWidgetOpen"
        @on-widget-change="widgetEventHandler"
      />
      <div class="container">
        <div class="beta-text">
          <marquee>{{ $t('beta_mode') }}</marquee>
        </div>
        <nav class="header-main__top-nav">
          <ul>
            <li>
              <router-link :to="`/${$i18n.locale}/about`" active-class="active">
                {{ $t('nav.about') }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="`/${$i18n.locale}/advertising`"
                active-class="active"
              >
                {{ $t('nav.adv') }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="`/${$i18n.locale}/feedback`"
                active-class="active"
              >
                {{ $t('nav.feedback') }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="`/${$i18n.locale}/contact`"
                active-class="active"
              >
                {{ $t('nav.contact') }}
              </router-link>
            </li>
          </ul>
          <button class="search-btn" @click="openSearch">
            <search-icon size="1.3x" />
          </button>
        </nav>
        <button class="widget-btn" @click="isWidgetOpen = true">
          <eye-icon class="mr-2" size="1.3x" />
          <span>{{ $t('specials') }}</span>
        </button>
        <social-links class="ml-4" />
      </div>
    </div>
    <div class="header-main__middle">
      <div class="container">
        <logo />
        <div class="header-date">
          {{ currentDate }}
        </div>
        <weather-select />
        <div class="courses">
          <div class="course course--up">USD {{ usd || '-' }}</div>
          <div class="course course--down">EUR {{ eur || '-' }}</div>
          <div class="course course--up">RUB {{ rub || '-' }}</div>
        </div>
        <lang-switcher />
      </div>
    </div>
    <div class="header-main__bottom">
      <nav-main />
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
require('dayjs/locale/uz')
require('dayjs/locale/ru')
require('dayjs/locale/uz-latn')
import { SearchIcon, EyeIcon } from 'vue-feather-icons'
import SocialLinks from '@/components/SocialLinks'
import Logo from '@/components/Logo'
import WeatherSelect from '@/components/WeatherSelect'
import LangSwitcher from '@/components/LangSwitcher'
import NavMain from '@/components/NavMain'
import Widget from '@/components/Widget'

export default {
  components: {
    SearchIcon,
    EyeIcon,
    SocialLinks,
    Logo,
    WeatherSelect,
    LangSwitcher,
    NavMain,
    Widget
  },
  data: () => ({
    isSearchOpened: false,
    isWidgetOpen: false,
    searchValue: ''
  }),
  computed: {
    currentDate() {
      return dayjs(new Date())
        .locale(this.$i18n.locale === 'oz' ? 'uz-latn' : this.$i18n.locale)
        .format('DD MMMM, dddd')
    },
    ...mapGetters(['usd', 'rub', 'eur'])
  },
  created() {
    Promise.all([
      this.$store.dispatch('getCurrency', 'USD'),
      this.$store.dispatch('getCurrency', 'RUB'),
      this.$store.dispatch('getCurrency', 'EUR')
    ])
  },
  watch: {
    isSearchOpened: {
      immediate: true,
      handler: 'noScroll'
    },
    isWidgetOpen: {
      immediate: true,
      handler: 'noScroll'
    }
  },
  methods: {
    openSearch() {
      this.isSearchOpened = true
      this.$refs.searchInput.focus()
    },
    widgetEventHandler(val) {
      this.isWidgetOpen = val
    },
    searchSubmit() {
      if (this.searchValue) {
        this.$router.push(`/${this.$i18n.locale}/search/${this.searchValue}`)
        this.isSearchOpened = false
        this.searchValue = ''
      }
    },
    noScroll(val) {
      if (val) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.header-main {
  background: #fff;
  .search-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 4;
    background: rgba(#000, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all;
    backdrop-filter: blur(2px);
    &--active {
      visibility: visible;
      opacity: 1;
    }
  }
  &__search {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    transform: translateY(-100%);
    transition: 0.2s all;
    z-index: 5;
    &.opened {
      transform: translateY(0);
    }
    .container {
      height: 100%;
      .search-btn {
        background: rgba(#000, 0.05);
        &:hover {
          background: rgba(#000, 0.08);
        }
        &:focus {
          outline: none;
          background: rgba(#000, 0.1);
        }
      }
    }
    .header-search-input {
      width: 100%;
      height: 100%;
      padding: 14px;
      border: none;
      transition: 0.2s all;
      background: rgba(#000, 0.05);
      &:hover {
        background: rgba(#000, 0.08);
      }
      &:focus {
        outline: none;
        background: rgba(#000, 0.1);
      }
    }
  }
  &__top {
    position: relative;
    border-bottom: 1px solid $border-color;
    font-size: 0.875rem;
    color: $text-light;
    line-height: 1.2;
    .container {
      display: flex;
    }
    .beta-text {
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      display: flex;
      align-items: center;
      padding: 12px;
    }
    &-nav {
      display: flex;
      align-self: stretch;
      ul {
        list-style: none;
        display: flex;
        align-items: stretch;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          align-items: stretch;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
            color: $text-light;
            transition: 0.2s all;
            &:hover,
            &:focus,
            &.active {
              outline: none;
              background: rgba(#000, 0.05);
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .search-btn,
  .widget-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: $text-light;
    transition: 0.2s all;
    background: transparent;
    border: none;
    &:hover,
    &:focus {
      outline: none;
      background: rgba(#000, 0.05);
      text-decoration: none;
    }
    &:active {
      background: rgba(#000, 0.1);
    }
  }
  .widget-btn {
    margin-left: auto;
  }
  &__middle {
    border-bottom: 1px solid $border-color;
    font-size: 0.875rem;
    color: $text-light;
    line-height: 1.2;
    padding-top: 24px;
    padding-bottom: 24px;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
