<template>
  <div class="header-mobile">
    <div class="header-mobile__top">
      <div class="header-search">
        <form
          @submit.prevent="searchSubmit"
          class="header-mobile__search"
          :class="{ opened: isSearchOpened }"
        >
          <div class="container d-flex">
            <input
              type="text"
              :placeholder="$t('search_site')"
              class="header-search-input"
              ref="searchInput"
              v-model="searchValue"
            />
            <button class="search-btn">
              <search-icon />
            </button>
          </div>
        </form>
        <div
          class="search-backdrop"
          :class="{ 'search-backdrop--active': isSearchOpened }"
          @click="isSearchOpened = false"
        ></div>
      </div>
      <b-container class="d-flex align-items-center justify-content-between">
        <button class="search-btn" @click="openSearch">
          <search-icon class="icon" />
        </button>
        <logo size="95" />
        <button class="menu-btn" @click="$emit('menu-click')">
          <menu-icon class="icon" />
        </button>
      </b-container>
    </div>
    <div class="header-mobile__bottom">
      <b-container class="d-flex align-items-center justify-content-between">
        <weather-select />
        <lang-switcher />
      </b-container>
    </div>
  </div>
</template>

<script>
import { SearchIcon, MenuIcon } from 'vue-feather-icons'
import WeatherSelect from '@/components/WeatherSelect'
import LangSwitcher from '@/components/LangSwitcher'
import Logo from '@/components/Logo'

export default {
  components: {
    SearchIcon,
    MenuIcon,
    Logo,
    WeatherSelect,
    LangSwitcher
  },
  data: () => ({
    isSearchOpened: false,
    searchValue: ''
  }),
  watch: {
    isSearchOpened: {
      immediate: true,
      handler: 'noScroll'
    }
  },
  methods: {
    searchSubmit() {
      if (this.searchValue) {
        this.$router.push(`/${this.$i18n.locale}/search/${this.searchValue}`)
        this.isSearchOpened = false
        this.searchValue = ''
      }
    },
    openSearch() {
      this.isSearchOpened = true
      this.$refs.searchInput.focus()
    },
    noScroll(val) {
      if (val) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.header-mobile {
  background: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  .search-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 4;
    background: rgba(#000, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all;
    backdrop-filter: blur(2px);
    &--active {
      visibility: visible;
      opacity: 1;
    }
  }
  &__search {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    transform: translateY(-100%);
    transition: 0.2s all;
    z-index: 5;
    &.opened {
      transform: translateY(0);
    }
    .container {
      height: 100%;
      .search-btn {
        background: rgba(#000, 0.05);
        &:hover {
          background: rgba(#000, 0.08);
        }
        &:focus {
          outline: none;
          background: rgba(#000, 0.1);
        }
      }
    }
    .header-search-input {
      width: 100%;
      height: 100%;
      padding: 14px;
      border: none;
      transition: 0.2s all;
      background: rgba(#000, 0.05);
      &:hover {
        background: rgba(#000, 0.08);
      }
      &:focus {
        outline: none;
        background: rgba(#000, 0.1);
      }
    }
  }
  &__top {
    border-bottom: 1px solid $border-color;
    position: relative;
  }
  &__bottom {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .search-btn,
  .menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: $text-light;
    transition: 0.2s all;
    background: transparent;
    border: none;
    &:hover,
    &:focus {
      outline: none;
      background: rgba(#000, 0.05);
      text-decoration: none;
    }
    &:active {
      background: rgba(#000, 0.1);
    }
  }
}
</style>
