<template>
  <router-link class="card-item-lg" :to="`/${$i18n.locale}/news/${item.id}`">
    <div class="card-item-lg__img-wrapper">
      <img
        :src="baseUrl + item.thumbnail.url"
        :alt="item.thumbnail.alternativeText"
      />
    </div>
    <div class="card-item-lg__content">
      <div class="card-item-lg__category">
        {{ item.category[`name_${$i18n.locale}`] }}
      </div>
      <div class="card-item-lg__title">
        {{ item[`title_${$i18n.locale}`] }}
      </div>
      <div class="card-item-lg__details">
        <div class="time card-item-lg__detail">
          {{ $date(item.publish_at || item.published_at).format('HH:mm') }}
        </div>
        <div class="date card-item-lg__detail">
          {{ $date(item.publish_at || item.published_at).format('DD.MM.YYYY') }}
        </div>
        <div class="views card-item-lg__detail">
          <eye-icon size="1.3x" class="icon" />
          <span>{{ item.views }}</span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { EyeIcon } from 'vue-feather-icons'

export default {
  components: {
    EyeIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.card-item-lg {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  text-decoration: none;
  &:hover,
  &:focus {
    .card-item-lg {
      &__img-wrapper {
        img {
          transform: scale(1.01);
        }
        &::after {
          opacity: 1;
        }
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    padding: 24px;
    line-height: 1.2;
  }
  &__category {
    font-size: 0.688rem;
    color: #ffffff;
    font-weight: bold;
    background: rgba(231, 192, 111, 0.8);
    border-radius: 2px;
    margin-bottom: 15px;
    padding: 4px 8px;
  }
  &__title {
    font-size: 1.125rem;
    line-height: 1.4;
    font-weight: bold;
    margin-bottom: 15px;
  }
  &__details {
    display: flex;
    align-items: center;
  }
  &__detail {
    font-weight: normal;
    color: rgba(255, 255, 255, 0.9);
    margin-right: 14px;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    .icon {
      margin-right: 5px;
    }
  }
  &__img-wrapper {
    position: relative;
    width: 100%;
    height: 500px;
    border-radius: 2px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform-origin: center;
      transition: transform 0.2s;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.8;
      transition: opacity 0.2s;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.9) 100%
      );
    }
  }
}

@media screen and (max-width: 576px) {
  .card-item-lg {
    &__img-wrapper {
      height: 300px;
    }
  }
}
</style>
