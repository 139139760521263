import axios from 'axios'
import config from '@/config'

export default {
  state: () => ({
    USD: null,
    EUR: null,
    RUB: null
  }),
  actions: {
    async getCurrency({ commit }, currency) {
      try {
        const response = await axios.get(
          'https://free.currconv.com/api/v7/convert',
          {
            params: {
              q: `${currency}_UZS`,
              compact: 'ultra',
              apiKey: config.currencyApiKey
            }
          }
        )
        commit(`set${currency}`, response.data)
      } catch (error) {
        console.log(error)
      }
    }
  },
  mutations: {
    setUSD(state, res) {
      state.USD = res
    },
    setRUB(state, res) {
      state.RUB = res
    },
    setEUR(state, res) {
      state.EUR = res
    }
  },
  getters: {
    usd: state => (state.USD ? state.USD.USD_UZS.toFixed(2) : null),
    rub: state => (state.RUB ? state.RUB.RUB_UZS.toFixed(2) : null),
    eur: state => (state.EUR ? state.EUR.EUR_UZS.toFixed(2) : null)
  }
}
