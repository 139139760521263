<template>
  <div class="home-page">
    <loader full :visible="isLoading" />
    <b-container class="mb-3 my-lg-3">
      <b-row>
        <b-col cols="12" lg="8">
          <b-row>
            <b-col cols="12" class="my-3" v-if="mainNews.length > 0">
              <main-slider :items="mainNews" />
            </b-col>
            <b-col
              v-for="news in secondaryNews"
              :key="news.id"
              xl="6"
              cols="12"
              class="my-3"
            >
              <card-item :item="news" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="4" class="my-3">
          <h3 class="mb-4">{{ $t('last_news') }}</h3>
          <b-row>
            <b-col
              v-for="news in lastNews"
              :key="news.id"
              cols="12"
              class="mb-4"
            >
              <card-item :item="news" no-image has-underline />
            </b-col>
          </b-row>
          <router-link
            :to="`/${$i18n.locale}/news`"
            class="button button--block button--default"
          >
            {{ $t('more_news') }}
          </router-link>
        </b-col>
      </b-row>
      <hr class="my-4" />
      <b-row>
        <b-col cols="12" lg="8">
          <section class="popular-news-section" v-if="popularNews.length > 0">
            <h3 class="mb-3">{{ $t('most_read') }}</h3>
            <b-row>
              <b-col
                v-for="news in popularNews"
                :key="news.id"
                cols="12"
                xl="6"
                class="my-3"
              >
                <card-item :item="news" />
              </b-col>
            </b-row>
          </section>
          <hr class="my-4" />
          <section class="video-news-section" v-if="videos.length > 0">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h3>{{ $t('video_news') }}</h3>
              <router-link :to="`/${$i18n.locale}/videos`" class="text-link">
                {{ $t('all') }}
              </router-link>
            </div>
            <b-row>
              <b-col xl="6" cols="12" class="mb-5 mb-xl-0">
                <card-item :item="videos[0]" size="md" card-type="video" />
              </b-col>
              <b-col xl="6" cols="12">
                <card-item
                  v-for="video in videos.slice(1)"
                  :key="video.id"
                  :item="video"
                  class="mb-4"
                  card-type="video"
                />
              </b-col>
            </b-row>
          </section>
          <hr class="my-4" v-show="false" />
          <section v-show="false" class="audio-section">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h3>{{ $t('audio_news') }}</h3>
              <a href="#" class="text-link">
                {{ $t('all') }}
              </a>
            </div>
            <b-row>
              <b-col
                class="my-3"
                cols="12"
                xl="6"
                v-for="(audio, index) in 6"
                :key="index"
              >
                <card-item card-type="audio" />
              </b-col>
            </b-row>
          </section>
        </b-col>
        <b-col cols="12" lg="4">
          <poster />
          <div class="social-networks-sidebar mt-4">
            <h3 class="mb-3">{{ $t('socials_title') }}</h3>
            <social-networks />
          </div>
          <hr class="mt-5 mb-4" v-show="false" />
          <photo-news-slider v-show="false" />
        </b-col>
      </b-row>
      <hr class="my-4" v-show="false" />
      <b-row v-show="false">
        <b-col cols="12">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h3>{{ $t('faq_title') }}</h3>
            <!-- <a href="#" class="text-link">
              {{ $t('all') }}
            </a> -->
          </div>
          <nav class="categories-nav my-4">
            <ul>
              <li>
                <a
                  href="#"
                  @click.prevent="filterBy = 'all'"
                  :class="{ active: filterBy === 'all' }"
                >
                  {{ $t('faqs.all') }}
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="filterBy = 'low'"
                  :class="{ active: filterBy === 'low' }"
                >
                  {{ $t('faqs.low') }}
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="filterBy = 'transport'"
                  :class="{ active: filterBy === 'transport' }"
                >
                  {{ $t('faqs.transportation') }}
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="filterBy = 'tax'"
                  :class="{ active: filterBy === 'tax' }"
                >
                  {{ $t('faqs.taxes') }}
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="filterBy = 'register'"
                  :class="{ active: filterBy === 'register' }"
                >
                  {{ $t('faqs.reg') }}
                </a>
              </li>
            </ul>
          </nav>
          <b-row>
            <b-col cols="12" lg="6" xl="4" class="mb-4 mb-lg-0">
              <card-item size="md" />
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <card-item v-for="news in 3" :key="news" class="mb-4" />
            </b-col>
            <b-col cols="12" xl="4" class="mt-4 mt-xl-0">
              <card-item
                v-for="news in 3"
                :key="news"
                class="mb-4"
                no-image
                has-underline
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import MainSlider from '@/components/MainSlider'
import CardItem from '@/components/cards/CardItem'
import Poster from '@/components/Poster'
import SocialNetworks from '@/components/SocialNetworks'
import PhotoNewsSlider from '@/components/PhotoNewsSlider'

export default {
  name: 'Home',
  components: {
    MainSlider,
    CardItem,
    Loader,
    Poster,
    SocialNetworks,
    PhotoNewsSlider
  },
  data: () => ({
    filterBy: 'all',
    isLoading: false,
    error: null,
    mainNews: [],
    secondaryNews: [],
    lastNews: [],
    popularNews: [],
    videos: []
  }),
  metaInfo() {
    return {
      title: this.$t('nav.main_page')
    }
  },
  created() {
    this.isLoading = true
    Promise.all([
      this.$store.dispatch('fetchNews', {
        _sort: 'publish_at:desc',
        _limit: 3
      }),
      this.$store.dispatch('fetchNews', {
        _sort: 'publish_at:desc',
        _start: 3,
        _limit: 6
      }),
      this.$store.dispatch('fetchNews', {
        _sort: 'publish_at:desc',
        _limit: 7
      }),
      this.$store.dispatch('fetchNews', {
        _sort: 'views:desc',
        _limit: 6
      }),
      this.$store.dispatch('fetchVideos', {
        _sort: 'publish_at:desc',
        _limit: 4
      })
    ])
      .then(([mainNews, secondaryNews, lastNews, popularNews, videos]) => {
        this.mainNews = mainNews
        this.secondaryNews = secondaryNews
        this.lastNews = lastNews
        this.popularNews = popularNews
        this.videos = videos
      })
      .catch(error => (this.error = error))
      .finally(() => (this.isLoading = false))
  }
}
</script>
