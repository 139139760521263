<template>
  <div class="page">
    <header-main v-if="!isMobile" />
    <header-mobile
      v-if="isMobile"
      @menu-click="isSidebarOpen = !isSidebarOpen"
    />
    <sidebar-nav
      v-if="isMobile"
      @close="isSidebarOpen = false"
      :is-open="isSidebarOpen"
    />
    <div class="page__socials" v-if="!isMobile">
      <social-links vertical />
    </div>
    <div class="page__body">
      <b-container class="my-5">
        <b-row>
          <!-- <b-col cols="12" md="5" data-aos="fade-up">
            <h2 class="mb-4">{{ $t('404_error') }}</h2>
            <p class="mb-4 not-found-desc">
              {{ $t('404_desc') }}
            </p>
            <router-link
              class="button button--default button--block"
              :to="'/' + $i18n.locale"
            >
              {{ $t('return_to_main') }}
            </router-link>
          </b-col> -->
        </b-row>
      </b-container>
    </div>
    <footer-main />
  </div>
</template>

<script>
import HeaderMain from '@/components/HeaderMain'
import FooterMain from '@/components/FooterMain'
import SidebarNav from '@/components/SidebarNav'
import SocialLinks from '@/components/SocialLinks'
import HeaderMobile from '@/components/HeaderMobile'
import isMobileMixin from '@/mixins/isMobile.mixin'

export default {
  mixins: [isMobileMixin],
  components: {
    HeaderMain,
    FooterMain,
    HeaderMobile,
    SidebarNav,
    SocialLinks
  },
  data: () => ({
    isSidebarOpen: false
  }),
  watch: {
    $route: {
      deep: true,
      handler() {
        this.isSidebarOpen = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__body {
    flex: 1;
    background: #fff;
  }
}

// ≥992px
@media (max-width: 991.98px) {
  .page {
    &__body {
      padding-top: 90px;
    }
  }
}
</style>
